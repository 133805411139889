import { errorHandler, successHandler } from "../../../services/_helper";
import { apiServices } from "../../../services/api.service";
import router from '../../../router';

export const getProjectDetails = async ({ commit }, { id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getProjectDetails(id).then(
    (response) => {
      commit("PROJECT_DETAILS", response.data);
      commit("SET_DATA_LOADER", false);
    
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const getUserProject = async ({ commit}, {formFileId }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProject(formFileId).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_DATA',response.data)
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const getUserProfile = async ({ commit }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProfile().then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_PROFILE',response.data)
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const getUserProjectList = async ({ commit }, {limit,offset,keyword }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProjectList(limit,offset,keyword).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_USER_PROJECT',response.data)
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const getOneProject = async ({ commit }, {id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getOneProject(id).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_PROJECT_DATA',response.data)
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

export const getFillFormList = async ({ commit }, {imageName }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getFillFormList(imageName).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_PROJECT_FILL_DATA',response.data)
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const getAllCollectionUserProject = async ({ commit}, {formFileId }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.getUserProject(formFileId).then(
    (response) => {
      commit("SET_DATA_LOADER", false);
      commit('SET_DATA',response.data)
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};
export const updateProject = async ({ commit }, {data }) => {
  commit("SET_LOADER", true, { root: true });
  await apiServices.updateProject(data).then(
    () => {
      commit("SET_LOADER", false, { root: true });
      successHandler('Update Successfull')
    },
    (error) => {
      commit("SET_LOADER", false, { root: true });
      errorHandler(error.response);
    }
  );
};
export const submitProject = async ({ commit }, { id,imageName,serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId }) => {
	commit("SET_LOADER", true, { root: true });
	await apiServices.submitProject(id,imageName,serialNo, title, firstName, lastName, initial, email, fatherName, dob, gender, profession, mailingStreet, mailingCity, mailingPostalCode, mailingCountry, serviceProvider, fileNo, referenceNo, simNo, typeOfNetwork, cellModelNo, imei1, imei2, typeOfPlan, creditCardType, contractValue, dateOfIssue, dateOfRenewal, installment, amountInWords, remarks, formFileId).then(
		() => {
			commit("SET_LOADER", false, { root: true });
			successHandler('Submit successfully');
		},
		(error) => {
			commit("SET_LOADER", false, { root: true });
			errorHandler(error.response);
		}
	);
};

export const submitUserProjectEnd = async ({ commit }, {id }) => {
  commit("SET_DATA_LOADER", true);
  await apiServices.submitUserProjectEnd(id).then(
    () => {
      commit("SET_DATA_LOADER", false);
      sessionStorage.clear()
			router.push({ path: '/login' })
    },
    (error) => {
      commit("SET_DATA_LOADER", false);
      errorHandler(error.response);
    }
  );
};

