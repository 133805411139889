import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/HomeUser.vue";

const routes = [
  {
    path: "/",
    name: "home",
    meta: {
      title: "Dashboard",
      requiresAuth: false,
    },
    component: Home,
  },
  {
    meta: {
      title: "Login", 
      fullScreen: true,
      requiresAuth: false,
    },
    path: "/login",
    name: "login",
    component: () => import("../views/LoginUser.vue"),
  },
  {
    meta: {
      title: "Project",
      requiresAuth: false,
    },
    path: "/default_project",
    name: "Default Project",
    redirect: "/project/all",
    component: () => import("../views/Project/IndexProject.vue"),
    children: [
      {
        path: "/project/all",
        name: "All Project",
        component: () => import("../views/Project/ProjectList.vue"),
      },
      {
        path: "/project/edit",
        name: "Update Project",
        component: () => import("../views/Project/EditList.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

router.beforeEach(async (to, from, next) => {

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
	if (requiresAuth && !localStorage.getItem('accessToken')) {
		next('/login');
    return;
	} else {
		next();
    return;

	}




  // const isAuthenticated = localStorage.getItem("accessToken");

  // if (to.matched.some((record) => record.meta.requiresAuth)) {
  //   if (isAuthenticated) {
  //     if (findRoute(to.path)) {
  //       next();
  //       return;
  //     } else {
  //       next(false);
  //       return;
  //     }
  //   } else {
  //     next("/login");
  //     return;
  //   }
  // }
  // next();
});

// function findRoute(path) {
//   let list = localStorage.getItem("permissions");
//   if (list) {
//     list = JSON.parse(list);
//   }

//   const objIndex = list.findIndex((obj) => obj.permissionEntity.url === path);
//   return objIndex >= 0 ? true : false;
// }
export default router;
