export const SET_LOAD_MORE = (state, status) => { 
  state.loadMore = status;
};

export const SET_DATA_LOADER = (state, status) => {
  state.dataLoader = status;
};

export const SET_POSTS1 = (state, data) => {
  state.data = data;
};

export const SET_POSTS2 = (state, data) => {
  state.data.push(...data);
};

export const SET_ACCOUNT = (state, data) => {
  state.account=data.account
  state.accountEmail=data.account?.email
  state.accountKeys=data.account?.keys
  state.accountId=data.accountId
  state.createdAt=data.createdAt
  state.delay=data.delay
  state.firstName=data.firstName
  state.id=data.id
  state.lastName=data.lastName
  state.level2=data.level2
  state.level3=data.level3
  state.mobile=data.mobile
  state.profile=data.profile
  state.profileName=data.profileName
  state.updatedAt=data.updatedAt
  state.userProject=data.userProject
  state.wallet = data.account?.wallet
};

export const SET_OTP_STATUS = (state, status) => {
  state.otpStatus = status; 
};

export const SET_MSG = (state, data) => {
  state.msg = data.msg;
  state.status = data.status;
};
export const SET_PROFILE = (state, data) => {
  state.user = data;

};
export const SET_PROFILE_IMAGE = (state, link) => {
  state.user.profile = link;
};
