<script setup>
import { computed, onBeforeMount } from "vue";
import { useStore } from "vuex";


const store = useStore();
const storeVar = computed(() => store.state);

const overlayClick = () => {
  store.dispatch("asideLgToggle", false);
};
onBeforeMount(() => {
  disableBackButton()

})


//the animating function


function disableBackButton() {
  document.addEventListener("contextmenu", (event) => {
    event.preventDefault();
  });
  function ctrlShiftKey(e, keyCode) {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  }

  document.onkeydown = (e) => {
    // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
    if (
      e.keyCode === 123 ||
      ctrlShiftKey(e, 'I') ||
      ctrlShiftKey(e, 'J') ||
      ctrlShiftKey(e, 'C') ||
      (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
    )
      return false;
  };
  const vueDetected = !!(window.__VUE__)
  if (vueDetected) {
    window.postMessage({
      // TODO disable devtools
      devtoolsEnabled: false,
      vueDetected: true
    }, '*')
    return
  }


}
</script>

<template>
  <nav-bar />
  <!-- <div id="numCount"></div> -->
  <!-- <aside-menu :menu="menu" /> -->
  <router-view />
  <!-- <br /> -->
  <!-- <footer-bar /> -->
  <base-overlay v-show="storeVar.isAsideLgActive" z-index="z-30" @overlay-click="overlayClick" />
  <base-Notification />
  <base-spinner1 v-if="storeVar.loader"></base-spinner1>
</template>
