import router from '../../router';
import * as store from '../../store';

export function errorHandler(res) {
	if (res) {
		if (res.status === 401) {
			setAlert({ type: "danger", message: res.data.message });
			sessionStorage.clear();
			router.push('/login');
			return;
		}
		if (res.status === 403) {
			setAlert({ type: 'danger', message: 'Sorry! Permission deined!' });
			return;
		}
		if (res.status === 409) {
			setAlert({ type: 'danger', message: res.data.message });
			return;
		}
		if (res.status === 400) {
			if (Array.isArray(res.data.message)) {
				setAlert({ type: 'danger', message: res.data.message[0] });
				return;
			} else {
				setAlert({ type: 'danger', message: res.data.message });
				return;
			}
		}
		if (res.status !== 400) {
			setAlert({ type: 'danger', message: res.data.message });
		}
	}
}

export function successHandler(msg) {
	setAlert({ type: 'success', message: msg });
}

function setAlert(data) {
	store.default.state.notification = data;
}
