
export const SET_LOAD_MORE = (state, status) => {
  state.loadMore = status;
};

export const SET_DATA_LOADER = (state, status) => {
  state.dataLoader = status;
};

export const SET_PROJECT_FILL_DATA = (state, data)=>{
  state.fillData=data
  state.fillData.forEach(element => {
    element.serialNodisable= true;
    element.titledisable= true;
    element.firstNamedisable= true;
    element.lastNamedisable= true;
    element.initialdisable= true;
    element.emaildisable= true;
    element.fatherNamedisable= true;
    element.dobdisable= true;
    element.genderdisable= true;
    element.professiondisable= true;
    element.mailingStreetdisable= true;
    element.mailingCitydisable= true;
    element.mailingPostalCodedisable= true;
    element.mailingCountrydisable= true;
    element.serviceProviderdisable= true;
    element.fileNodisable= true;
    element.referenceNodisable= true;
    element.simNodisable= true;
    element.typeOfNetworkdisable= true;
    element.cellModelNodisable= true;
    element.imei1disable= true;
    element.imei2disable= true;
    element.typeOfPlandisable= true;
    element.creditCardTypedisable= true;
    element.contractValuedisable= true;
    element.dateOfIssuedisable= true;
    element.dateOfRenewaldisable= true;
    element.installmentdisable= true;
    element.amountInWordsdisable= true;
    element.remarksdisable= true;
  });
}

export const SET_DATA = (state, data) => {
  state.data = data;
  if(data.length>0){
    state.dataObjKey =  Object.keys(data[0])
    for (let index = 0; index < state.dataObjKey.length; index++) {
      const element = state.dataObjKey[index];
      if (element !== 'id' && element !== 'status' && element !== 'accountId' &&
        element !== 'formFileId' && element !== 'createdAt' && element !== 'updatedAt') {
        state.ObjKey.push(element)
      }
    }
  }

  const obj = state.ObjKey.reduce((accumulator, value) => {
    return {...accumulator, [value]: null};
  }, {});
  for (let index = 0; index < state.data.length; index++) {
    const element = state.data[index];
    const pushData={
      element,key:obj
    }
    state.allData.push(pushData)
  }
};
export const SET_PROFILE=(state,data)=>{
  state.createdAt=data.createdAt
  state.createdBy=data.createdBy
  state.email=data.email
  state.id=data.id
  state.keys=data.keys
  state.lastStatus=data.lastStatus
  state.loginId=data.loginId
  state.name=data.name
  state.phoneNumber=data.phoneNumber
  state.roles=data.roles
  state.status=data.status
  state.totalProject=data.totalProject
  state.updatedAt=data.updatedAt
  state.userProject=data.userProject
  state.userProjectSubId=data.userProject[0]?.id
  state.userProjectStartDate=data.userProject[0]?.startDate
  state.userProjectEndDate=data.userProject[0]?.endDate
}

export const SET_USER_PROJECT = (state, data) => {
  state.userProject = data;
};

export const SET_UPDATE_STAFF = (state, data) => {
  const objIndex = state.data.findIndex((obj) => obj.id === data.id);
  state.data[objIndex].designation = data.designation;
  state.data[objIndex].username = data.username;
};
export const FORM_DATA = (state, data) => {
  state.formData = data.formData
  state.formId = data.id
  state.projectData = data.project
};
export const FORM_DATA_SUBMIT = (state, data) => {
  state.projectData = data
};

export const SET_PROJECT_DATA=(state,data)=>{
  state.accountId=data.accountId
  state.amountInWords=data.amountInWords
  state.cellModelNo=data.cellModelNo
  state.contractValue=data.contractValue
  state.createdAt=data.createdAt
  state.creditCardType=data.creditCardType
  state.dateOfIssue=data.dateOfIssue
  state.dateOfRenewal=data.dateOfRenewal
  state.dob=data.dob
  state.email=data.email
  state.fatherName=data.fatherName
  state.fileNo=data.fileNo
  state.firstName=data.firstName
  state.formCollectionId=data.formCollectionId
  state.formCollection=data.formCollection
  state.gender=data.gender
  state.id=data.id
  state.imageName=data.imageName
  state.imei1=data.imei1
  state.imei2=data.imei2
  state.initial=data.initial
  state.installment=data.installment
  state.lastName=data.lastName
  state.mailingCity=data.mailingCity
  state.mailingCountry=data.mailingCountry
  state.mailingPostalCode=data.mailingPostalCode
  state.mailingStreet=data.mailingStreet
  state.profession=data.profession
  state.referenceNo=data.referenceNo
  state.remarks=data.remarks
  state.result=data.result
  state.serialNo=data.serialNo
  state.serviceProvider=data.serviceProvider
  state.simNo=data.simNo
  state.status=data.status
  state.title=data.title
  state.typeOfNetwork=data.typeOfNetwork
  state.typeOfPlan=data.typeOfPlan
  state.updatedAt=data.updatedAt
  state.updatedBy=data.updatedBy
  state.userProjectId=data.userProjectId
}

