export default {
  totalAcceptedBid:0,
  totalActivePost:0,
  totalPendingBid:0,
  totalPost:0,
  totalRejectedBid:0,
  totalStaff:0,
  totalUser:0,
  totalVendor:0,
  telemedicines: [],
  clinicAppointment: [],
  liveDoctor: [],
  eMedicalBoard: [],
  labTests: [],
  doctors: [],
  registeredPatients: [],
  loader: false,
};
