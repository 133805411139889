export const SET_LOADER = (state, status) => {
	state.loader = status;
};

export const SET_DATA = (state, data) => {
	state.totalAcceptedBid = data.totalAcceptedBid
	state.totalActivePost = data.totalActivePost
	state.totalPendingBid = data.totalPendingBid
	state.totalPost = data.totalPost
	state.totalRejectedBid = data.totalRejectedBid
	state.totalStaff = data.totalStaff
	state.totalUser = data.totalUser
	state.totalVendor = data.totalVendor

};


// for only assessment
export const SET_DATA_ASSE = (state, data) => {
	state.assessments = data;
};


// for only Telemedicines
export const SET_DATA_TELE = (state, data) => {
	state.telemedicines = data;
};


// for only eMedical
export const SET_DATA_EML = (state, data) => {
	state.eMedicalBoard = data;
};


// for only Lab test
export const SET_DATA_LBT = (state, data) => {
	state.labTests = data;
};


// for only Doctor
export const SET_DATA_DOC = (state, data) => {
	state.doctors = data;
};

// for only patient
export const SET_DATA_PSND = (state, data) => {
	state.registeredPatients = data;
};


// for only patient
export const SET_DATA_PAY = (state, data) => {
	state.payments = data;
};


// for only subscriptionCounts
export const SET_DATA_SUB = (state, data) => {
	state.subscription = data;
};
