

<template>
  <hero-bar>Dashboards</hero-bar>
  <main-section>
    <!-- <input type="datetime-local"> -->
    <div class="grid grid-cols-1 gap-6 lg:grid-cols-3 mb-6">
      <card-widget color="text-green-500" :icon="mdiFolderOpen" label="Project" class="cursor-pointer"
        @click.prevent="navigate('/project/all')" empty />
      <card-widget color="text-green-500" :icon="mdiAccountCircle" label="Profile" class="cursor-pointer"
        @click.prevent="openProfile" empty />
      <card-widget color="text-green-500" :icon="mdiArchiveArrowUpOutline" label="Submit Project" class="cursor-pointer"
        @click.prevent="CloseTab" empty />
    </div>
    <modal-box v-model="formVar.profileModal" class="profile-modal" title="Profile">
      <section v-if="storeVar.userProject?.length > 0">
        <div class="grid grid-cols-2 lg:grid-cols-4 gap-4 py-6 profile-data-list">
          <label class="font-semibold text-sm text-red-400 unselectable">Name </label>
          <label class="font-semibold text-sm text-slate-800 dark:text-[#fff] unselectable"> :&nbsp;&nbsp; {{
            storeVar.name }}
          </label>
          <label class="font-semibold text-sm text-red-400 unselectable">Email </label>
          <label class="font-semibold text-sm text-slate-800 dark:text-[#fff] unselectable"> :&nbsp;&nbsp; {{
            storeVar.email }}
          </label>
          <label class="font-semibold text-sm text-red-400 unselectable">Username </label>
          <label class="font-semibold text-sm text-slate-800 dark:text-[#fff] unselectable"> :&nbsp;&nbsp; {{
            storeVar.loginId }}
          </label>
          <label class="font-semibold text-sm text-red-400 unselectable">Password </label>
          <label class="font-semibold text-sm text-slate-800 dark:text-[#fff] unselectable"> :&nbsp;&nbsp; {{
            storeVar.keys }}
          </label>
          <label class="font-semibold text-sm text-red-400 unselectable">Start Date </label>
          <label class="font-semibold text-sm text-slate-800 dark:text-[#fff] unselectable"> :&nbsp;&nbsp; {{
            getDate(storeVar.userProject[0]?.startDate) }}
          </label>
          <label class="font-semibold text-sm text-red-400 unselectable">End Date </label>
          <label class="font-semibold text-sm text-slate-800 dark:text-[#fff] unselectable"> :&nbsp;&nbsp; {{
            getDate(storeVar.userProject[0]?.endDate) }}
          </label>
        </div>
        <base-buttons type="justify-center lg:justify-center pt-5">
          <base-button type="button" color="info" small label="Close" v-if="!storeVar.loaderNew"
            @click.prevent="formVar.profileModal = false" />
        </base-buttons>
      </section>
    </modal-box>
    <modal-box v-model="formVar.modelNotStart" title="Alart !!!">
      <section>
        <div class="flex justify-center">
          <h5 class="text-blue-500">Your project has not started !</h5>
        </div>
        <base-buttons type="justify-center lg:justify-center pt-5">
          <base-button type="button" color="info" small label="Close" @click.prevent="formVar.modelNotStart = false" />
        </base-buttons>
      </section>
    </modal-box>
    <modal-box v-model="formVar.modelEnd" title="Alart !!!">
      <section>
        <div class="flex justify-center">
          <h5 class="text-blue-500">Your project has timed out !</h5>
        </div>
        <base-buttons type="justify-center lg:justify-center pt-5">
          <base-button type="button" color="info" small label="Close" @click.prevent="formVar.modelEnd = false" />
        </base-buttons>
      </section>
    </modal-box>
  </main-section>
</template>
<script setup>
import {
  mdiFolderOpen, mdiAccountCircle, mdiArchiveArrowUpOutline
} from '@mdi/js'
import moment from 'moment'
import { computed, onBeforeMount, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'


const store = useStore()
const router = useRouter()
const storeVar = computed(() => store.state.Project)
const formVar = reactive({
  dateTime: null,
  profileModal: false,
  modelNotStart: false,
  modelEnd: false,
})
onBeforeMount(() => {
  loadData()
})
function loadData() {
  store.dispatch("Project/getUserProfile");
}
function openProfile() {
  formVar.profileModal = true
}
function navigate(name) {
  let date1 = new Date();
  let startDate = new Date(storeVar.value.userProject[0]?.startDate);
  let endDate = new Date(storeVar.value.userProject[0]?.endDate);
  if (date1 > startDate && date1 < endDate) {
    if (date1.getTime() > startDate.getTime() && date1.getTime() < endDate.getTime()) {
      router.push({ path: name })
    }
  } else if (date1 > startDate) {
    formVar.modelEnd = true
  } else if (date1 < startDate) {
    formVar.modelNotStart = true
  }

}
function CloseTab() {
  store.dispatch('Project/submitUserProjectEnd', {
    id: storeVar.value.userProjectSubId
  })

}
function getDate(date) {
  if (date) {
    return moment(date).utcOffset("+05:30").format('DD-MM-YYYY, h:mm A');
  }
  return "-";
}
</script>